import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import { SwitchOnHover } from '../components/Card/UserCard';
import { withPrefix } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #0d181c !important;
  h1 {
    margin-top: 30px;
    color: white !important;
  }
  .red {
    background: #e30613;
    color: white;
    padding: 15px;
    h3 {
      color: white;
    }
  }
  @media (max-width: 750px) {
    .userCard {
      width: 100% !important;
    }
  }
  ul {
    list-style: circle;
    padding-left: 30px;
  }
  .bigOnHover {
    background-size: 200%;
    transition: all 0.2s;
    background-repeat: no-repeat;
    &:hover {
      background-size: 350%;
    }
  }
`;

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.props.firebase.base.listenToCollection('users', {
      context: this,
      state: 'users',
      then() {
        this.props.trainings.getTrainings().then(trainings => this.setState({ trainings }));
      }
    });
  }

  componentDidMount() {
    if (this.props.firebase) {
      this.props.firebase.getUsers().then(users =>
        this.setState({ users }, async () => {
          const usersWithRating = await Promise.all(
            this.state.users.map(async user => {
              if (user.id) {
                const lastMonthRating = await this.props.firebase.getLastMonthRating(user.id);
                if (user.id === 't3ccee9LY5UEcBCCz2Aep7rSkDy1') {
                }
                const lastMonthRatingSummery = lastMonthRating.reduce((acc, cur) => {
                  return acc + (parseInt(cur.ratingInc) || 0);
                }, 0);
                return {
                  ...user,
                  lastMonthRatingSummery
                };
              }
              return user;
            })
          );
          this.setState({ users: usersWithRating });
        })
      );
    }
  }

  componentWillUnmount() {
    this.mount = false;
  }

  state = {
    users: []
  };
  render() {
    return (
      <Wrapper>
        <SwitchOnHover
          image={withPrefix(`sigma/group/1.jpg`)}
          hoverAvatarNumber={withPrefix(`sigma/group/3.jpg`)}
          style={{ height: 'calc(100vh - 60px)' }}
          name="Sigma Software - Unicorn 2019"
        />

        <div className="container ">
          <h1 className="text-center">Тренер</h1>
          <SwitchOnHover
            image={withPrefix(`sigma/45.jpg`)}
            hoverAvatarNumber={withPrefix(`sigma/46.jpg`)}
            style={{ height: '500px' }}
            name="Дмитро Воловод - майстер спорту, видає півтора кіловата"
          />
        </div>
        <section>
          <div className="container">
            <h1 className="text-center">Механік</h1>
            <div
              className="bigOnHover"
              style={{
                backgroundPosition: '50% 40%',
                height: '500px',
                backgroundImage:
                  'url(https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/61760602_348125439238771_5040255468883148800_n.jpg?alt=media&token=ec22cef2-e3d9-41d3-ac88-670cb89aca4c)'
              }}
            />
            <div className="red">
              Самойленко Володимир - професійний веломеханік.
              <br />
              Працював механіком в ISD Continental team, KOLSS – BDC team, ISD-Jorbi Continental,
              Team Hurom, <b>CCC Sprandi Polkowice team</b>
            </div>

            <h1 className="text-center">Команда</h1>
            <div className="red">
              Команда Sigma Software - Unicorn існує з 2016 року, в її складі спортсмени-любителі з
              найрізноманітнішими цілями, рівнем підготовки й досвідом.
              <br />
              <br />
              <h3>Трофеї 2019:</h3>
              <ul>
                <li>
                  Групова гонка Kiev Spring 27 квітня - Дмитро Воловод 1 місце, Олена Ахметшина 2
                  місце
                </li>
                <li>
                  Київський Критеріум 28 квітня - Дмитро Воловод 1 місце, Олена Ахметшина 3 місце
                </li>
                <li>
                  Групова гонка Кубок Сіверщини 11-12 травня - Дмитро Воловод 1 місце, Олена
                  Ахметшина 2 місце
                </li>
                <li>
                  Групова багатоденна гонка Тур Галичини 17-19 травня - Дмитро Воловод 1 місце,
                  Олена Ахметшина 3 місце
                </li>
                <li>Тріатлон Слов'янська Хвиля 1 червня - Должиков Гліб 2 місце</li>
              </ul>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.state.users
                .filter(dude => dude.avatarNumber)
                .filter(dude => !dude.volovod)
                .map(user => {
                  const name = user.desc ? user.username + ' - ' + user.desc : user.username;
                  return (
                    <SwitchOnHover
                      image={withPrefix(`sigma/${user.avatarNumber}.jpg`)}
                      hoverAvatarNumber={withPrefix(`sigma/${user.hoverAvatarNumber}.jpg`)}
                      style={{ height: '400px', width: 'calc(50% - 20px)', margin: 10 }}
                      className={'userCard'}
                      name={name}
                    />
                  );
                })}
            </div>
          </div>
        </section>
      </Wrapper>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Contacts));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
